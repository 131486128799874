<script type="text/javascript">
import { Bar } from 'vue-chartjs'
export default {
  extends:Bar,
  props: {
    updated: {
    type: Boolean,
    required: false
    },
    chartData:Object,
    chartOptions:Object,
  },
  name: "Bar",
  components: {},
  data() {
    return {};
  },
  computed: {},
  created() {
      
  },
  mounted() {
      this.renderChart(this.chartData, this.chartOptions)
  },
  watch: {
    updated() {
      
      this.renderChart(this.chartData, this.chartOptions)
    },
    chartData() {
      this.renderChart(this.chartData, this.chartOptions)
    }
  },
  methods: {
      
  },
};
</script>
<style src="./Barchart.less" lang="less" />
